@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  src: url("../fonts/Poppins/Poppins-Thin.ttf");
}
@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: url("../fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: url("../fonts/Poppins/Poppins-Black.ttf");
}

.max-width {
  @apply px-5 lg:mx-auto lg:px-24 xl:px-48;
}
.bg-gradient {
  @apply bg-gradient-to-r from-sky-500 via-purple-500 to-pink-600;
}
.text-gradient {
  @apply text-transparent bg-clip-text bg-gradient;
}
.section {
  @apply pt-28 xl:pt-28 2xl:pt-44;
}
.text-content {
  @apply dark:text-light-content text-dark-content;
}
body {
  overflow-x: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
